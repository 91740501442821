<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-img height="100vh" src="../../assets/images/LoginBg.jpg" class="align-center">
        <v-layout wrap justify-center class="login_page">
          <v-flex xs12 sm6 md4 lg4 px-2 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 md6 text-center pb-6>
                <router-link to="/">
                <span style="
                        font-family: GillSansUltraBold;
                        color: white;
                        font-size: 28px;
                      ">JewelBIZ</span></router-link></v-flex>
              <v-flex xs12 xl10>
                <v-snackbar v-model="showSnackBar" color="#3F033B" right :timeout="timeout">
                  <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                      <span style="color: #ffffff">
                        {{ msg }}
                      </span>
                    </v-flex>
                    <v-flex text-right>
                      <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: #ffffff">mdi-close</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-snackbar>
                <v-card tile elevation="5" class="rounded-lg" style="border-bottom: 3px solid #2f1842">
                  <v-layout wrap justify-center>
                    <v-flex px-8 py-6>
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs12 lg6 px-2>
                              <span style="
                                      font-family: DmSansRegular;
                                      font-weight: 700;
                                      font-size: 25px;
                                      color: #2f1842;
                                    ">
                                LOG IN
                              </span>
                              <!-- <v-progress-linear
                              height="2"
                              value="100"
                              color="#2F1842"
                            ></v-progress-linear> -->
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex pt-8 xs12 text-left v-if="!chooseFlag && !pretoken">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field color="#717171" label="Username" outlined dense v-model="username"
                                  hide-details class="rounded-lg">
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-center pt-4>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field color="#717171" label="Password" outlined dense v-model="password"
                                  :append-icon="
                                    show1 ? 'mdi-eye' : 'mdi-eye-off'
                                  " :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" hide-details
                                  class="rounded-lg">
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <!-- Choose Role  -->

                        <v-flex pt-8 xs12 align-self-center text-left v-else>
                          <v-layout wrap justify-center>
                            <v-flex xs12 pa-2 align-self-center text-center>
                              <span class="kumbhMedium">Choose Position</span>
                            </v-flex>
                            <v-flex xs12 align-self-center py-2>
                              <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12 align-self-center text-center>
                              <v-radio-group dense v-model="position">
                                <v-radio style="font-family: KumbhMedium !important" color="#3F033B"
                                  v-for="(item, i) in positionList" :key="i" :label="item.name" :value="item._id"
                                  :title="item.project.projectName"></v-radio>
                              </v-radio-group>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-6 v-if="!chooseFlag && !pretoken">
                          <v-btn block tile dark light :ripple="false" depressed @click="validateInput"
                            class="itemValue rounded btn1">
                            Login
                          </v-btn>
                        </v-flex>
                        <v-flex xs12 lg5 py-6 v-if="chooseFlag || pretoken">
                          <v-btn block tile dark color="#3F033B" light :ripple="false" depressed @click="applyRole()"
                            class="itemValue rounded-xl">
                            Continue
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      username: null,
      name:null,
      password: null,
      chooseFlag: false,
      position: null,
      positionList: null,
      pretoken: null,
      show1: false,
    };
  },

  methods: {

    validateInput() {
      if (!this.username) {
        this.msg = "Please Provide username";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      var userData = {};
      userData["username"] = this.username;
      userData["password"] = this.password;
      store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "/user/login",
        data: userData,
      })
        .then((response) => {
          store.commit("appLoading", false);
          if (response.data.status) {
            store.commit("userType", response.data.mainRole);
            localStorage.setItem("role",response.data.mainRole)
            store.commit("userData", response.data.data);
            store.commit("fullData", response.data);
            store.commit("id", response.data.data.id);

            store.commit("loginUser", response.data.data.token);
            localStorage.setItem("id", response.data.data.id);
            this.userinfo();
            // store.commit("menu", response.data.menus);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    userinfo() {
      axios({
      method: "GET",
      url: "/user/info",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params:{
        id:localStorage.getItem("id")
      }
    })
      .then((response) => {
        // console.log("menu-loginpage");
        if (response.data.status) {
          console.log("1111")
          this.$store.commit("menu", response.data.menus);
          console.log("1112")

          // localStorage.setItem("menu",response.data.menus);
          // console.log("1113")

          localStorage.setItem("GSTstatus",response.data.data.taxType);
          // var check = response.data.goldrateadded;
          // if(check==false){
          //   console.log("check===-=",check)
          //   store.commit("queryPopUpDialog", true);
          // }

         
         
        }
        else{
          console.log("router")
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
        // store.commit("sessionOut", true);
      });
    },

    // applyRole() {
    //   if (!this.position) {
    //     this.msg = "Please choose a position!";
    //     this.showSnackBar = true;
    //     return;
    //   }
    //   var userData = {};
    //   userData["position"] = this.position;
    //   store.commit("appLoading", true);
    //   axios({
    //     method: "POST",
    //     url: "/employee/switchposition",
    //     data: userData,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("pretoken"),
    //     },
    //   })
    //     .then((response) => {
    //       store.commit("appLoading", false);
    //       if (response.data.status) {
    //         localStorage.removeItem("pretoken");
    //         store.commit("userData", response.data.data);
    //         this.$store.commit("fullData", response.data);
    //         store.commit("menu", response.data.menus);
    //         store.commit("loginUser", response.data.token);
    //         console.log("logmenu", response.data.menus);
    //         this.chooseFlag = false;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       store.commit("appLoading", false);
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
<style scoped>
.btn1 {
  background-image: linear-gradient(180deg, #181053 -21.94%, #65446a 110.93%);
}
</style>